import { EMPTY_OBJECT } from '../../constants';

export const toProxyTrap = (
  { map, getValue },
  hashKey,
  requestConfigFn,
  relevant0 = {}
) => {
  const relevant = { ...relevant0 };
  const ourMap = map?.[hashKey] ?? EMPTY_OBJECT;
  const handler = {
    get: function (target, prop = '') {
      if (prop === '@@toStringTag') {
        //Report back that we are an object. This happens when one of these proxies is passed to any form of react prop
        //type validator. It tires to figured out what we are and becomes angry if we are honest
        return 'object';
      }
      const value = target?.[prop];

      if (value !== undefined) {
        //Track that we asked
        relevant[prop] = value;
        return value;
      }

      const requested = getValue(prop, hashKey, requestConfigFn);
      //Track that we asked, we will get the appropriate default value
      relevant[prop] = requested;

      return requested;
    },
  };

  const proxy = new Proxy(ourMap, handler);
  return {
    relevant,
    proxy,
  };
};

export const updatesOurData = (relevant, latest = EMPTY_OBJECT) => {
  if (latest === EMPTY_OBJECT) return false;

  return Object.entries(relevant).some(([key, value]) => {
    return latest[key] !== value;
  });
};
