import { interval, from } from 'rxjs';
import {
  switchMap,
  filter,
  mergeMap,
  distinct,
  tap,
  bufferWhen,
  ignoreElements,
  pluck,
  distinctUntilChanged,
} from 'rxjs/operators';

import { query } from 'services/propertys/xe-propertys-svc';
import { MERGE_PATCH } from '../../schema/JSONSchemaReducer';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { ofChangedPropWhenExists } from '../../frp/operators/ofChangedPropWhenExists';

import { backgroundReducer } from '../../service/toRequestFns';
import { toGetValue } from '../utils/toRequestProxy';
import { toRequestPartitioner } from '../utils/toRequestPartitioner';
import { identity } from 'lodash';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';

const toAppPropertyData$ = (action$, state$, { menuNode$, window$ }) => {
  return state$.pipe(
    ofChangedPropWhenExists('requestSubject$'),
    combineWithLatestFrom(window$.pipe(pluck('location'))),
    switchMap(([requestSubject$, location]) => {
      const requestPartitioner = toRequestPartitioner(
        location,
        query,
        '&propName='
      );

      return requestSubject$.pipe(
        distinct((request) => request?.prop),
        bufferWhen(() => interval(50)),
        filter((ar) => ar.length > 0),
        mergeMap((requests) => {
          const [{ hashKey, requestConfigFn }] = requests;

          const partitionedKeys = requestPartitioner(
            requests.map((request) => request?.prop)
          );

          return from(
            partitionedKeys.map((requestKeys) => {
              return {
                hashKey,
                requestConfigFn,
                requestKeys,
              };
            })
          );
        }),
        combineWithLatestFrom(menuNode$.pipe(pluck('requestInvoker'))),
        mergeMap(
          ([{ hashKey, requestConfigFn, requestKeys }, requestInvoker]) => {
            const finalConfig = requestConfigFn(
              backgroundReducer
            )(/*No more reducers*/)(
              query({ propName: requestKeys.join('|') }, identity)
            );

            return requestInvoker(/*No additional args*/)(finalConfig).then(
              ({ results: values = EMPTY_ARRAY } = EMPTY_OBJECT) => {
                const value = requestKeys.reduce((acc, id) => {
                  const entry =
                    values.find(({ PropName }) => PropName === id) || {};

                  const { PropValue } = entry;
                  acc[id] = PropValue;
                  return acc;
                }, {});

                return {
                  type: MERGE_PATCH,
                  path: ['map', hashKey],
                  value,
                };
              }
            );
          }
        )
      );
    })
  );
};

const toAppPropertySubjectSideEffect$ = (action$, state$) => {
  return state$.pipe(
    ofChangedPropWhenExists('map'),
    combineWithLatestFrom(
      state$.pipe(ofChangedPropWhenExists('requestSubject$')),
      state$.pipe(ofChangedPropWhenExists('subject$'))
    ),
    distinctUntilChanged(),
    tap(([map, requestSubject$, subject$]) => {
      const nextObservation = {
        map,
        getValue: toGetValue(map, requestSubject$),
      };
      subject$.next(nextObservation);
    }),
    ignoreElements()
  );
};

export default [toAppPropertyData$, toAppPropertySubjectSideEffect$];
