import { useContext, useMemo, createContext, createElement } from 'react';
import { EMPTY_OBJECT } from '../../constants';
import useAuthCheckingWithSideEffects, {
  AUTHENTICATED,
} from './hooks/useAuthCheckingWithSideEffects';
import { ReactXeMenuNodeContext } from '../XeMenuNodeContext';
import { toLegacyBaseRequestFn } from '../../hooks/scopedReducer';
import { useQueryClient } from 'react-query';

export const toAuthenticatedRequestInvoker = (
  authProvider,
  authClient,
  requestInvoker
) => {
  return (additionalData = {}) => {
    return (config) => {
      const promise = authClient.getTokenSilently().then((accessToken) => {
        const { headers = EMPTY_OBJECT, ...restConfig } = config;

        const authHeader =
          authProvider === 'xnet'
            ? `XNet ${accessToken}`
            : `Bearer ${accessToken}`;

        const configWithAuth = {
          ...restConfig,
          headers: { ...headers, Authorization: authHeader },
        };

        return requestInvoker(additionalData)(configWithAuth);
      });

      return promise;
    };
  };
};

export const XeAuthenticationInitialContext = Object.freeze({
  authClient: EMPTY_OBJECT,
});

export const ReactXeAuthenticationContext = createContext(
  XeAuthenticationInitialContext
);
ReactXeAuthenticationContext.displayName = 'XeAuthenticationContext';

export const useXeAuthentication = () => {
  return useContext(ReactXeAuthenticationContext);
};

export const XeAuthenticationContext = (props) => {
  const {
    universe = EMPTY_OBJECT,
    authClient,
    requestConfigFn,
    requestInvoker,
  } = props;

  const { environment: { authProvider } = EMPTY_OBJECT } = universe;

  const queryClient = useQueryClient();

  const authenticationStatus = useAuthCheckingWithSideEffects(
    universe,
    authClient
  );

  const authRequestInvoker = useMemo(
    () =>
      toAuthenticatedRequestInvoker(authProvider, authClient, requestInvoker),
    [authProvider, authClient, requestInvoker]
  );

  const value = useMemo(() => {
    return {
      authClient,
      requestConfigFn,
      requestInvoker: authRequestInvoker,
    };
  }, [authClient, requestConfigFn, authRequestInvoker]);

  const menuContextValue = useMemo(() => {
    return {
      XeAppMenuNode: EMPTY_OBJECT,
      UniqueNodeName: '',
      componentPath: '',
      requestConfigFn,
      requestInvoker: authRequestInvoker,
      requestFn: toLegacyBaseRequestFn(
        queryClient,
        requestConfigFn,
        authRequestInvoker
      ),
    };
  }, [authRequestInvoker, requestConfigFn, queryClient]);

  const { children } = props;

  if (authenticationStatus !== AUTHENTICATED) {
    //Proceed no further until we have authenticated
    return null;
  }

  return createElement(
    ReactXeAuthenticationContext.Provider,
    {
      value: value,
      key: 'XeAuthenticationContext.Provider',
    },
    createElement(
      ReactXeMenuNodeContext.Provider,
      {
        value: menuContextValue,
        key: 'XeAuthenticationContext.Scope',
      },
      children
    )
  );
};
