import { useEffect } from "react";
import { EMPTY_OBJECT } from "../../../constants";

const findNode = pred => XeAppMenus => {
  const checkNode = node =>
    node.XeAppMenus ? pred(node) || findNode(pred)(node.XeAppMenus) : false;

  return (
    Array.isArray(XeAppMenus) &&
    XeAppMenus.reduce((bool, XeAppMenu) => bool || checkNode(XeAppMenu), false)
  );
};

const validateUserDataResponse = (userData = EMPTY_OBJECT) => {
  const {
    EnterpriseID,
    XeAppMenus,
    XeEnterpriseData,
    DefaultSearchFeature,
    DefaultFeature,
  } = userData;

  if (!EnterpriseID) throw new Error('The user has no enterprise ID.');

  if (!Array.isArray(XeEnterpriseData) || XeEnterpriseData.length === 0)
    throw new Error('The user has no enterprises.');

  if (
    !XeEnterpriseData.find(
      enterprise => enterprise.EnterpriseID === EnterpriseID
    )
  )
    throw new Error(
      'The user has an enterprise ID that does not exist in their available enterprises.'
    );

  if (!XeAppMenus || XeAppMenus.length === 0 || !XeAppMenus[0].XeAppMenus)
    throw new Error('The user has no XeAppMenus.');

  if (
    !XeAppMenus.some(
      ({ HtmlComponentName = '' }) =>
        HtmlComponentName.indexOf('XeApplication') === 0
    )
  )
    throw new Error(
      'The user does not have an Application feature identified by HtmlComponentName in root XeAppMenus'
    );

  const invalidXeAppMenuExists = false;
  // !checkForValidXeAppMenus(
  //   XeAppMenus[0].XeAppMenus
  // );

  if (invalidXeAppMenuExists)
    throw new Error(
      'Visible menu data was provided without a HtmlComponentName.'
    );

  if (DefaultSearchFeature) {
    const validDefaultSearchFeature = findNode(
      ({ MenuNodeID }) => DefaultSearchFeature === MenuNodeID
    )(XeAppMenus[0].XeAppMenus);

    if (!validDefaultSearchFeature)
      throw new Error(
        'A default search feature was provided that does not exist in the provided menu data'
      );
  }
  if (DefaultFeature) {
    const validDefaultFeature = findNode(
      ({ MenuNodeID, HtmlComponentName } = {}) => {
        return MenuNodeID === DefaultFeature && HtmlComponentName;
      }
    )(XeAppMenus[0].XeAppMenus);
    if (!validDefaultFeature)
      throw new Error(
        `The default feature provided by the menu data is not routable. Route: ${DefaultFeature}`
      );
  }
};

export const useUserDataValidationSideEffect = (userData)=>{
  useEffect(()=>{
    if (userData) {
      validateUserDataResponse(userData);
    }
  }, [userData]);
};