import { useContext, createContext } from 'react';
import { EMPTY_OBJECT } from '../../constants';

const undefinedContext = EMPTY_OBJECT;

export const ReactXeSystemContext = createContext(
  undefinedContext
);
ReactXeSystemContext.displayName = 'XeSystemContext';

export const useSystem = () => {
  return useContext(ReactXeSystemContext);
};

export const XeSystemContext = ReactXeSystemContext.Provider;
