import {
  getLoginMenu,
  loginEnterprise,
} from 'services/user-datas/xe-user-datas-svc';
import { EMPTY_OBJECT } from '../../../constants';
import { HEADER_XE_ENTERPRISE_ID } from '../../../service/constants';
import { toHeaderReducer } from '../../../service/data';
import { toCacheKeyFromRequest } from '../../../service/serviceCache';

const DEFAULT_RESPONSE = {
  AllResults: true,
  NumberOfResults: 1,
  NumberRestricted: 0,
  RestrictProcessed: true,
  Restricted: false,
};

//Caches a call to loginEnterprise that did NOT have an enterprise into the correct enterprise dictated by the response
//This is necessary as we call loginEnterprise BOTH to find out our enterprise... and to switch enterprises
export const withProjectedEnterpriseCacheSideEffect =
  (queryClient, toConfig) =>
  (loginEnterpriseResult = EMPTY_OBJECT) => {
    const reducedConfig = toConfig(loginEnterprise({}, (x) => x));
    const queryKey = toCacheKeyFromRequest(reducedConfig);
    const lastResults = queryClient.getQueryData(queryKey)?.results;

    if (loginEnterpriseResult?.results !== lastResults) {
      queryClient.setQueryData(queryKey, loginEnterpriseResult);
    }
  };

export const withMenuNodeCacheSideEffect =
  (queryClient, toConfig) =>
  (XeAppMenuNode = EMPTY_OBJECT) => {
    const recurseNode = (XeAppMenuNode) => {
      const { XeAppMenus = [], MenuNodeID: menuNodeId } = XeAppMenuNode;

      const reducedConfig = toConfig(getLoginMenu({ menuNodeId }, (x) => x));
      const queryKey = toCacheKeyFromRequest(reducedConfig);
      const lastResults = queryClient.getQueryData(queryKey)?.results;

      if (lastResults !== XeAppMenuNode) {
        const response = {
          ...DEFAULT_RESPONSE,
          results: XeAppMenuNode,
        };

        queryClient.setQueryData(queryKey, response);

        XeAppMenus.forEach((node) => {
          return recurseNode(node);
        });
      }
    };

    //We don't want to try to cache the top level as a MenuNode... it is not, it's just a login response, we need to cache
    //its children
    const { XeAppMenus = [] } = XeAppMenuNode;

    XeAppMenus.forEach((node) => {
      recurseNode(node);
    });
  };

export const cacheMenuTree = (queryClient, toConfig, data = EMPTY_OBJECT) => {
  const { results: XeAppMenuNode = EMPTY_OBJECT } = data;

  const { EnterpriseID } = XeAppMenuNode;

  //Okay a bit tricky here. Our initial login may not have been with an enterprise.... but, we really still want to cache
  //the result in the primary enterprise, we we need to do a bit of work to make that feasible
  const requestConfigFn = toConfig(
    toHeaderReducer(HEADER_XE_ENTERPRISE_ID)(EnterpriseID)
  )();

  //First the loginEnterpriseResponse, in the proper enterprise
  withProjectedEnterpriseCacheSideEffect(queryClient, requestConfigFn)(data);

  //Then the menuNodes themselves
  withMenuNodeCacheSideEffect(queryClient, requestConfigFn)(XeAppMenuNode);
};
