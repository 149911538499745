import { EMPTY_OBJECT } from "../../../constants";

export const atMenuNodeIDs = (...menuNodeIDs) => (
  menuNodeResult = EMPTY_OBJECT,
  // indexInTransforms,
  // transforms
) => {
  const {
    results : XeAppMenuNode = EMPTY_OBJECT
  } = menuNodeResult; 

  const { MenuNodeID } = XeAppMenuNode;

  return menuNodeIDs.includes(MenuNodeID);
};

export const withDelta = (toDelta) => (menuNodeResult = EMPTY_OBJECT /*, indexInTransforms, transforms */) => {

  const {
    results : XeAppMenuNode = EMPTY_OBJECT
  } = menuNodeResult; 
  
  const { Delta } = XeAppMenuNode;
  const transformedDelta = toDelta(Delta);

  if (transformedDelta === Delta) {
    //lets keep identity if we can
    return menuNodeResult;
  }

  return {
    ...menuNodeResult,
    results : {
      ...XeAppMenuNode,
      Delta : transformedDelta
    }
  };
};

// export const withRecursiveInlineModification = (predicate, projection) => (userData) => {
//   const recurseNode = (node) => {
//     const { XeAppMenus = [], ...rest } = node;
// Discouraging the use of this... we should modify each menu node at a time so we don't get into weird state refresh issues now
// that they can refresh
//     const updatedMenus = XeAppMenus.map((node, i, arr) => {
//       const maybeNewNode = predicate(node, i, arr)
//         ? projection(node, i, arr)
//         : node;

//       return recurseNode(maybeNewNode);
//     });

//     return {
//       XeAppMenus: updatedMenus,
//       ...rest,
//     };
//   };

//   return recurseNode(userData);
// };

export const withInlineModification = (...transforms) => (menuNodeResult=EMPTY_OBJECT) => {
  return transforms.reduce((newNode, [predicate, projection], i, o)=>{
    return predicate(newNode, i, o)
      ? projection(newNode, i, o)
      : newNode;
  }, menuNodeResult);
};