import { Observable } from 'rxjs';
import { ping } from 'services/authentications/xe-authentications-svc';
import { notifyManager, useQueryClient } from 'react-query';
import { useEffect$ } from '../../../hooks/useEffect$';
import { debounceTime, ignoreElements, mergeMap, NEVER } from 'rxjs';
import { useMenuNode } from '../../XeMenuNodeContext';
import { backgroundReducer } from '../../../service/toRequestFns';
import { neverCache } from '../../../service/serviceCache';

import { EMPTY_OBJECT } from '../../../constants';

const minutesToMilliseconds = (time) => time * 60 * 1000;
const localStorageQueryFilter = {
  predicate: (query) => {
    //TODO It would be better to rewrite this to check for only queries to REST endpoints rather than specifically eliminate queries to localStorage
    const { options: { isLocalStorage = false } = {} } = query;

    return !isLocalStorage;
  },
};

export const useKeepAliveSideEffect$ = ({
  KeepAliveTimerInterval,
} = EMPTY_OBJECT) => {
  const queryClient = useQueryClient();
  const { requestFn } = useMenuNode();

  useEffect$(() => {
    if (KeepAliveTimerInterval === undefined) {
      return NEVER;
    }

    const observable = new Observable((subscriber) => {
      const unsubscribe = queryClient.getQueryCache().subscribe(
        notifyManager.batchCalls(() => {
          const isFetching = queryClient.isFetching(localStorageQueryFilter);
          if (isFetching) {
            subscriber.next(isFetching);
          }
        })
      );

      return unsubscribe;
    });

    //This is simpler now as the ping itself will show something as isFetching, so we don't need to keep a separate interval
    return observable.pipe(
      debounceTime(minutesToMilliseconds(KeepAliveTimerInterval)),
      mergeMap(() => {
        const backgroundRequestFn = requestFn(
          backgroundReducer,
          neverCache
        )(/*No more reducers*/)(/*No additional props*/);
        return ping({}, backgroundRequestFn);
      }),
      ignoreElements()
    );
  }, [queryClient, requestFn, KeepAliveTimerInterval]);
};

export default useKeepAliveSideEffect$;
