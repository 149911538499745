import { useContext, useMemo, createContext } from 'react';
//import { useQueryClient } from 'react-query';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { identity } from '../../fp/fp';
import { withMoreReducers } from '../../hooks/scopedReducer';

export const XeMenuNodeInitialContext = Object.freeze({
  UniqueNodeName: '',
  componentPath: '',
  XeAppMenuNode: EMPTY_OBJECT,
  requestConfigFn: withMoreReducers(() => identity)(),
  requestInvoker: () => NOOP_FUNCTION,
  requestFn: () => console.error('Calling undefined request fn'),
});

export const ReactXeMenuNodeContext = createContext(XeMenuNodeInitialContext);
ReactXeMenuNodeContext.displayName = 'XeMenuNodeContext';

export const useMenuNode = () => {
  return useContext(ReactXeMenuNodeContext);
};

export const useDescendentComponentContextValue = (identifier, MenuNodeID) => {
  const value = useMenuNode();

  return useMemo(() => {
    //This is already attached to a menu node.... I would rather do this in reverse, which is decorate here but that is going to take some doing
    if (MenuNodeID) return undefined;

    const { UniqueNodeName = '', componentPath = '' } = value;

    return {
      ...value,
      UniqueNodeName: `${UniqueNodeName}/c-${identifier}`,
      componentPath: `${componentPath}/c-${identifier}`,
      XeAppMenuNode: EMPTY_OBJECT,
    };
  }, [identifier, MenuNodeID, value]);
};
