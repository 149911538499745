import { of, interval, from, identity } from 'rxjs';
import {
  map,
  switchMap,
  filter,
  mergeMap,
  distinct,
  tap,
  bufferWhen,
  take,
  switchMapTo,
  ignoreElements,
  pluck,
  distinctUntilChanged,
  groupBy,
} from 'rxjs/operators';

import { queryCache } from 'services/labels/xe-labels-svc';
import { MERGE_PATCH, UPSERT } from '../../schema/JSONSchemaReducer';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { ofChangedPropWhenExists } from '../../frp/operators/ofChangedPropWhenExists';

import { backgroundReducer } from '../../service/toRequestFns';
import { toGetValue } from '../utils/toRequestProxy';
import { toRequestPartitioner } from '../utils/toRequestPartitioner';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';

const toFallbackLabels$ = (/*action$, state$, dependencies*/) => {
  return of('REST/labels.json').pipe(
    switchMap((url) => {
      return fetch(url);
    }),
    switchMap((response) => {
      if (response.ok) {
        return response.json();
      }

      return of({});
    }),
    map((json) => {
      const value = json['en_US'] ?? {};
      return {
        type: UPSERT,
        path: 'localOverides',
        value,
      };
    })
  );
};

const toServerLabels$ = (action$, state$, { menuNode$, window$ }) => {
  return state$.pipe(
    ofChangedPropWhenExists('requestSubject$'),
    combineWithLatestFrom(window$.pipe(pluck('location'))),
    switchMap(([requestSubject$, location]) => {
      const requestPartitioner = toRequestPartitioner(
        location,
        queryCache,
        '&labelId='
      );

      return requestSubject$.pipe(
        groupBy((request) => request?.hashKey),
        mergeMap((group$) => {
          return group$.pipe(
            distinct((request) => request?.prop),
            bufferWhen(() => {
              return state$.pipe(
                filter((state) => {
                  //Right now this is checked per group, but it feels like a minimal amount of overhead
                  const { localOverides } = state;

                  return localOverides; //Simply waiting until we know we have our local overrides before beginning to hit the server
                }),
                take(1),
                switchMapTo(interval(50))
              );
            }),
            filter((ar) => ar.length > 0),
            mergeMap((requests) => {
              const [{ hashKey, requestConfigFn }] = requests;

              const partitionedKeys = requestPartitioner(
                requests.map((request) => request?.prop)
              );

              return from(
                partitionedKeys.map((requestKeys) => {
                  return {
                    hashKey,
                    requestConfigFn,
                    requestKeys,
                  };
                })
              );
            }),
            combineWithLatestFrom(
              menuNode$.pipe(pluck('requestInvoker')),
              state$.pipe(ofChangedPropWhenExists('localOverides'))
            ),
            mergeMap(
              ([
                { hashKey, requestConfigFn, requestKeys },
                requestInvoker,
                localOverides,
              ]) => {
                const finalConfig = requestConfigFn(
                  backgroundReducer
                )(/*No more reducers*/)(
                  queryCache({ labelId: requestKeys }, identity)
                );

                return requestInvoker(/*No additional args*/)(finalConfig).then(
                  ({ results: values = EMPTY_ARRAY } = EMPTY_OBJECT) => {
                    const value = requestKeys.reduce((acc, id) => {
                      const { Name } =
                        values.find(({ LabelID }) => LabelID === id) || {};
                      //First layer of fallback is the local override file
                      //Per SYNUI-3604, next layer of fallback is the actual id of the label
                      acc[id] = Name ?? localOverides[id] ?? id;
                      if (!(Name ?? localOverides[id])) {
                        console.warn(
                          `Label ${id} not found in request or local overrides`
                        );
                      }
                      return acc;
                    }, {});

                    return {
                      type: MERGE_PATCH,
                      path: ['map', hashKey],
                      value,
                    };
                  }
                );
              }
            )
          );
        })
      );
    })
  );
};

const toLabelSubjectSideEffect$ = (action$, state$) => {
  return state$.pipe(
    ofChangedPropWhenExists('map'),
    combineWithLatestFrom(
      state$.pipe(ofChangedPropWhenExists('requestSubject$')),
      state$.pipe(ofChangedPropWhenExists('subject$'))
    ),
    distinctUntilChanged(),
    tap(([map, requestSubject$, subject$]) => {
      const nextObservation = {
        map,
        getValue: toGetValue(map, requestSubject$),
      };
      subject$.next(nextObservation);
    }),
    ignoreElements()
  );
};

export default [toFallbackLabels$, toServerLabels$, toLabelSubjectSideEffect$];
