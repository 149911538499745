import {
  Fragment,
  useMemo,
  // eslint-disable-next-line no-unused-vars
  useRef,
  createElement,
  Suspense,
  // eslint-disable-next-line no-unused-vars
  useEffect,
} from 'react';
import { generatePath, Navigate, useParams, useRoutes } from 'react-router-dom';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';
import { useXeQuery } from '../../data/useXeQuery';
import { toLegacyBaseRequestFn } from '../../hooks/scopedReducer';
import {
  ReactXeMenuNodeContext,
  useMenuNode,
  XeMenuNodeInitialContext,
} from '.';
import { useSystem } from '../XeSystemContext';
import { useEnterprise } from '../XeEnterpriseContext';
import { hasDeltas, nestDelta, toDeltaReducer } from './deltas';
import {
  /*atMenuNodeIDs,*/ withDelta,
  withInlineModification,
} from '../utils/userData/inlineModification';
import { useFactory } from '../../hooks/useFactory';
import {
  APP_FUNCTION_MENU,
  FUNCTION,
  MENUGROUP,
  MENUGROUPSIDETREE,
  MENU_DATUM,
} from './constants';
import { getLoginMenu } from 'services/user-datas/xe-user-datas-svc';
import { useQueryClient } from 'react-query';
import { useRouteLocationDetails } from '../../hooks/useRouteLocationDetails';

const DefaultSearchFeatureNavigate = ({ to, replace }) => {
  const params = useParams();
  const revisedTo = generatePath(to, params);

  return <Navigate to={revisedTo} replace={replace} />;
};

export const withMenuNodeID = (MenuNodeID) => (XeAppMenuNode) =>
  XeAppMenuNode?.MenuNodeID === MenuNodeID;
const NEVER_MATCH = () => false;

const UNNAMED_NODE = 'g';
const QUALIFIED_SEPARATOR = '_';
export const toPreferredName = (
  XeAppMenuNode = EMPTY_OBJECT,
  unique = true
) => {
  const { MenuNodeID, Name = UNNAMED_NODE, HtmlComponentName } = XeAppMenuNode;

  const nodeName = HtmlComponentName ?? encodeURIComponent(Name);

  return unique && nodeName !== UNNAMED_NODE
    ? nodeName
    : `${nodeName}${QUALIFIED_SEPARATOR}${MenuNodeID}`;
};

// eslint-disable-next-line no-unused-vars
export const useRemountWatcher = (name) => {
  return;
  // const ref = useRef(undefined);

  // if (ref.current === undefined) {
  //   // eslint-disable-next-line no-console
  //   console.log(`Mounting ${name}`);
  //   ref.current = 1;
  // } else {
  //   // eslint-disable-next-line no-console
  //   console.log(`Rendering ${name} (${ref.current})`);
  //   ref.current = ref.current + 1;
  // }

  // useEffect(() => {
  //   return () => {
  //     // eslint-disable-next-line no-console
  //     console.log(`Unmounting ${name}`);
  //   };
  // }, [name]);
};

// eslint-disable-next-line no-unused-vars
const inlineModification = withInlineModification([
  () => false, //atMenuNodeIDs(242500), //Leaving this here so it can be easily debugged
  withDelta((existing = {}) => {
    const { XeDashboard = {} } = existing;
    return {
      XeDashboard: {
        ...XeDashboard,
        services: {
          service: [
            {
              action: 'querySummary',
              headers: {
                // 'xe-pagesize': 15,
                'xe-pagesize': 35,
                // 'xe-pagesize': 200,
                'xe-querysort': 'asc:category',
              },
              service: 'xe-dashboards-svc',
            },
          ],
        },
      },
    };
  }),
]);

//Janky.... this thing is set as inline, but then is a menu side tree.... need to fix all of this
export const isSelectableFeature = (XeAppMenuNode = EMPTY_OBJECT) => {
  const { MenuType } = XeAppMenuNode;

  return MenuType !== MENUGROUPSIDETREE;
};

export const isRoutableNode = (XeAppMenuNode = EMPTY_OBJECT) => {
  const { Active, MenuType } = XeAppMenuNode;

  return Active && MenuType !== MENU_DATUM;
};

export const matchesMenuNode = (XeAppMenuNode, name = '') => {
  //We need to be immune to the end slash.... so if someone tries thing/foo or thing/foo/ they should not need
  //to be concerned with the existence of the trailing slash when matching
  const adjustedName = name.endsWith('/') ? name.slice(0, -1) : name;

  const { MenuNodeID, HtmlComponentName, Name } = XeAppMenuNode;

  if (adjustedName.includes(QUALIFIED_SEPARATOR)) {
    const [, id] = adjustedName.split(QUALIFIED_SEPARATOR);
    return id == MenuNodeID; //Do not use strict equality on the last as numbers are tricky here
  }

  return HtmlComponentName === adjustedName || Name === adjustedName;
};

export const toIsUniqueAmongSiblings = (peers) => (name) =>
  peers.filter(
    ({ Name = UNNAMED_NODE, HtmlComponentName = Name }) =>
      HtmlComponentName === name
  ).length == 1;

//TODO Janky : this should just be a direct projection to much if logic here
const toRequiredArguments = (XeAppMenuNode = EMPTY_OBJECT) => {
  const { HtmlComponentName } = XeAppMenuNode;

  if (HtmlComponentName === 'XePatientSide') {
    return [':ipid'];
  }

  if (HtmlComponentName === 'XeNotifications') {
    return ['*'];
  }

  return [];
};

export const toAbsoluteUrlFromMenuNode = (
  userData = EMPTY_OBJECT,
  menuNodeMatcher = NEVER_MATCH,
  params = EMPTY_OBJECT
) => {
  const {
    XeAppMenus: [ApplicationNode = EMPTY_OBJECT] = EMPTY_ARRAY,
    EnterpriseID = '',
  } = userData;

  const { XeAppMenus = EMPTY_ARRAY } = ApplicationNode;

  let nodePath = '';

  try {
    nodePath = generatePath(
      toPathFromMenuNodes(
        XeAppMenus,
        menuNodeMatcher,
        '',
        toIsUniqueAmongSiblings(XeAppMenus)
      ),
      params
    );
  } catch ({ message = '' }) {
    console.warn(message);
  }

  return `/${EnterpriseID}${nodePath}/`;
};

export const toMenuNode = (XeAppMenus = [], menuNodeMatcher = NEVER_MATCH) => {
  if (!(Array.isArray(XeAppMenus) && XeAppMenus.length)) {
    return undefined;
  }

  const [head = EMPTY_OBJECT, ...tail] = XeAppMenus;
  const { XeAppMenus: headXeAppMenus = EMPTY_ARRAY } = head;

  if (menuNodeMatcher(head)) {
    return head;
  }

  const descendant = toMenuNode(headXeAppMenus, menuNodeMatcher);

  if (descendant) return descendant;

  return toMenuNode(tail, menuNodeMatcher);
};

const segmentResolver = (segments = EMPTY_ARRAY, XeAppMenus = EMPTY_ARRAY) => {
  const [head = '', ...preliminaryTail] = segments;

  const XeAppMenuNode = XeAppMenus.find((XeAppMenuNode) => {
    return XeAppMenuNode?.Active && matchesMenuNode(XeAppMenuNode, head);
  });

  if (!XeAppMenuNode) {
    return [];
  }

  const additionalArguments = toRequiredArguments(XeAppMenuNode);

  //We might take additional arguments here, and, if we do, we have to 'consume' them from the URL before continuing our match
  const tail = preliminaryTail.slice(additionalArguments.length);

  if (!tail.length || !XeAppMenuNode?.XeAppMenus) {
    return [XeAppMenuNode];
  }

  return [XeAppMenuNode, ...segmentResolver(tail, XeAppMenuNode?.XeAppMenus)];
};

const hasSegment = (p) => p !== undefined && p !== '';
export const toMenuNodesFromPath = (path = '', XeAppMenus = EMPTY_ARRAY) => {
  const [entepriseID = '', ...segments] = path.split('/').filter(hasSegment);
  const [XeAppMenuNode = EMPTY_OBJECT] = XeAppMenus;

  const { EnterpriseID } = XeAppMenuNode;

  if (EnterpriseID !== entepriseID) {
    //We aren't in the right enterprise per the URL.... no routes
    return EMPTY_ARRAY;
  }

  return [
    XeAppMenuNode,
    ...segmentResolver(segments, XeAppMenuNode?.XeAppMenus),
  ];
};

export const toPathFromMenuNodes = (
  XeAppMenus = [],
  menuNodeMatcher = NEVER_MATCH,
  path = '',
  isUniqAmongSiblings = () => true
) => {
  if (!(Array.isArray(XeAppMenus) && XeAppMenus.length)) {
    //&& MenuNodeID (not sure of the purpose of this condition before)
    return undefined;
  }

  const [head = EMPTY_OBJECT, ...tail] = XeAppMenus;
  const {
    //MenuNodeID: headMenuNodeID,
    Name: headName = UNNAMED_NODE,
    HtmlComponentName: headHtmlComponentName = headName,
    XeAppMenus: headXeAppMenus = EMPTY_ARRAY,
  } = head;

  const requiredArgs = toRequiredArguments(head);
  const unique = isUniqAmongSiblings(headHtmlComponentName);
  //const qualifiedPathName = toPreferredName(head, unique);
  const qualifiedPathName = requiredArgs.reduce((final, argument) => {
    if (argument === '*') return final;
    return `${final}/${argument}`;
  }, toPreferredName(head, unique));

  const potentialDescendantName = qualifiedPathName
    ? `${path}/${qualifiedPathName}`
    : path;

  if (menuNodeMatcher(head)) {
    return potentialDescendantName;
  }

  const descendantWithPath = toPathFromMenuNodes(
    headXeAppMenus,
    menuNodeMatcher,
    potentialDescendantName,
    toIsUniqueAmongSiblings(headXeAppMenus)
  );

  if (descendantWithPath) return descendantWithPath;
  return toPathFromMenuNodes(tail, menuNodeMatcher, path, isUniqAmongSiblings);
};

const errorFeature = () =>
  createElement('div', {}, 'This feature does not seem to exist');

export const toFeature = (featureCollection, HtmlComponentName) => {
  const { fn, component: LazyComponent } =
    featureCollection[HtmlComponentName] || {};

  if (!(fn || LazyComponent)) {
    return errorFeature;
  }

  if (fn) {
    return fn;
  }

  return (props) => {
    useRemountWatcher(`Lazy::${HtmlComponentName}`);

    return (
      <Suspense fallback={null}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

const HtmlContext = ({ XeAppMenuNode, children }) => {
  const Context = useHtmlContext(XeAppMenuNode);

  const { HtmlContextName } = XeAppMenuNode;
  return (
    <Context key={HtmlContextName} XeAppMenuNode={XeAppMenuNode}>
      {children}
    </Context>
  );
};

//const EMPTY = () => <div></div>;
//const Passthrough = ({children}) => <>{children}</>;
//TODO Janky : this should just be a direct projection to much if logic here
export const toFeatureFromNode = (featureCollection, XeAppMenuNode) => {
  const { HtmlComponentName, MenuType, MenuNodeID } = XeAppMenuNode;

  if (MenuType === MENUGROUPSIDETREE) {
    return toFeature(featureCollection, 'XePassThroughRoute');
  } else if (MenuType === APP_FUNCTION_MENU) {
    return toFeature(featureCollection, 'XeAppFunctions');
  } else if (HtmlComponentName !== undefined) {
    return toFeature(featureCollection, HtmlComponentName);
  } else if (MenuType === MENUGROUP) {
    return toFeature(featureCollection, 'XeMenuRoute');
  } else {
    throw new Error(
      `MenuNodeID ${MenuNodeID} has a missing HtmlComponentName ${HtmlComponentName} OR unexpected MenuType ${MenuType}, please review`
    );
  }
};

// const fromLoginEnterprise = (queryClient, id) => {
//   queryClient.getQueryData('todos')?.find(d => d.id === todoId)
// };
//<Navigate to="/login" />
const createChildRoutes =
  (isUniqueAmongSiblings, stickyRoutes) => (routes, XeAppMenuNode) => {
    const {
      Name = UNNAMED_NODE,
      HtmlComponentName = Name,
      MenuNodeID,
      MenuType,
      XeAppMenus: AllChildXeAppMenus = EMPTY_ARRAY,
    } = XeAppMenuNode;
    //uniqueness

    const { HtmlContextName } = XeAppMenuNode;
    const HtmlContextFeature =
      HtmlContextName && HtmlContextName !== 'XeMenuContext'
        ? HtmlContext
        : Fragment;

    //TODO Revisit me, I need to handle MENUGROUP properly
    if (MenuType === APP_FUNCTION_MENU || MenuType === FUNCTION) {
      return routes;
    }

    const unique = isUniqueAmongSiblings(HtmlComponentName);
    const requiredArgs = toRequiredArguments(XeAppMenuNode);

    const qualifiedPathName = toPreferredName(XeAppMenuNode, unique);
    const XeAppMenus = AllChildXeAppMenus.filter(isRoutableNode);
    const hasChildren = XeAppMenus.length;

    if (hasChildren) {
      const isUniqueChild = toIsUniqueAmongSiblings(XeAppMenus);

      //For now we are disabling sticky routes until it can have sufficient testing
      const lastChildMenuNodeID = undefined;
      //const { [MenuNodeID]: lastChildMenuNodeID } = stickyRoutes.current;

      //If this is the type of route where we have routed children.... and the child was not specified
      //then fallback to whatever we last observed
      const [ChildXeAppMenuNode = EMPTY_OBJECT] =
        lastChildMenuNodeID === undefined
          ? XeAppMenus
          : XeAppMenus.filter(
              ({ MenuNodeID }) => MenuNodeID === lastChildMenuNodeID
            );
      const qualifiedChildPathName = toPreferredName(
        ChildXeAppMenuNode,
        isUniqueChild(ChildXeAppMenuNode?.HtmlComponentName)
      );

      const finalPathName = requiredArgs.reduce((final, argument) => {
        return `${final}/${argument}`;
      }, qualifiedPathName);

      const contextProps =
        HtmlContextFeature !== Fragment ? { XeAppMenuNode } : {};

      //Do we need to provide a soft landing page for users that need to derive the variable (e.g. ipid in a patient search)
      const argumentIntermediateRoute =
        finalPathName !== qualifiedPathName
          ? [
              {
                path: `${qualifiedPathName}/`,
                element: (
                  <HtmlContextFeature key={MenuNodeID} {...contextProps}>
                    <MenuNodeLeafFeature
                      key={MenuNodeID}
                      menuNodeId={MenuNodeID}
                      path={qualifiedPathName}
                    />
                  </HtmlContextFeature>
                ),
              },
            ]
          : [];

      return [
        ...routes,
        ...argumentIntermediateRoute,
        {
          path: `${finalPathName}`,
          element: (
            <Navigate to={`./${qualifiedChildPathName}/`} replace={true} />
          ),
        },
        {
          path: `${finalPathName}/*`,
          element: (
            <HtmlContextFeature key={MenuNodeID} {...contextProps}>
              <MenuNodeBranchFeature
                key={MenuNodeID}
                menuNodeId={MenuNodeID}
                path={qualifiedPathName}
                stickyRoutes={stickyRoutes}
              />
            </HtmlContextFeature>
          ),
        },
      ];
    }

    const finalPathName = requiredArgs.reduce((final, argument) => {
      return `${final}/${argument}`;
    }, qualifiedPathName);

    return [
      ...routes,
      {
        path: `${finalPathName}`,
        element: (
          <MenuNodeLeafFeature
            key={MenuNodeID}
            menuNodeId={MenuNodeID}
            path={qualifiedPathName}
          />
        ),
      },
    ];
  };

const useHtmlContext = (XeAppMenuNode = EMPTY_OBJECT) => {
  const { featureCollection } = useSystem();
  const { HtmlContextName = '' } = XeAppMenuNode;

  return useFactory(() => {
    if (!HtmlContextName) return Fragment;

    return toFeature(featureCollection, HtmlContextName);
  }, [featureCollection, HtmlContextName]);
};

const useLazyLoadedFeature = (XeAppMenuNode = EMPTY_OBJECT) => {
  const { featureCollection } = useSystem();

  return useFactory(() => {
    if (XeAppMenuNode === EMPTY_OBJECT) {
      return null;
    }

    return toFeatureFromNode(featureCollection, XeAppMenuNode);
  }, [featureCollection, XeAppMenuNode]);
};

const useDescendantMenuContext = (menuNodeId) => {
  const queryClient = useQueryClient();
  const parentMenuNodeContext = useMenuNode();

  //Regardless of where we find ourselves in the hierarchy, we always need to make this
  //Call with the raw enterprise request so we do not absorb other deltas
  const { requestConfigFn } = useEnterprise();

  const { data: XeAppMenuNode = EMPTY_OBJECT } = useXeQuery(
    getLoginMenu({ menuNodeId }, (x) => x),
    {
      enabled: menuNodeId !== undefined,
      requestConfigFn,
      select: inlineModification,
      staleTime: Infinity,
      //initialData: () => fromLoginEnterprise(queryClient, menuNodeId),
    }
  );

  const { DeltaLinkID } = XeAppMenuNode;

  const { data: DeltaLinkData = EMPTY_OBJECT } = useXeQuery(
    getLoginMenu({ menuNodeId }, (x) => x),
    {
      enabled: DeltaLinkID !== undefined,
      requestConfigFn,
      staleTime: Infinity,
      //initialData: () => fromLoginEnterprise(queryClient, menuNodeId),
    }
  );

  const { Delta: LinkedDelta } = DeltaLinkData;

  const DeltaPending =
    DeltaLinkID !== undefined && DeltaLinkData === EMPTY_OBJECT;

  const value = useMemo(() => {
    if (XeAppMenuNode === EMPTY_OBJECT || DeltaPending) {
      return XeMenuNodeInitialContext;
    }

    const {
      Delta = EMPTY_OBJECT,
      HtmlComponentName = '',
      MenuNodeID,
    } = XeAppMenuNode;

    const {
      requestConfigFn,
      requestInvoker,
      componentPath: parentComponentPath = '',
    } = parentMenuNodeContext;

    const resolvedDeltas = nestDelta(LinkedDelta ?? Delta);

    const requestReducers = hasDeltas(resolvedDeltas)
      ? [toDeltaReducer(resolvedDeltas)]
      : [];

    const updatedRequestConfigFn = requestReducers.length
      ? requestConfigFn(...requestReducers)
      : requestConfigFn;

    const UniqueNodeName = `${HtmlComponentName}${QUALIFIED_SEPARATOR}${MenuNodeID}`;
    const componentPath = `${parentComponentPath}/${UniqueNodeName}`;

    return {
      UniqueNodeName,
      componentPath,
      XeAppMenuNode: {
        ...XeAppMenuNode,
        Delta: resolvedDeltas,
      },
      requestConfigFn: updatedRequestConfigFn,
      requestInvoker,
      requestFn: toLegacyBaseRequestFn(
        queryClient,
        updatedRequestConfigFn,
        requestInvoker
      ),
    };
  }, [
    XeAppMenuNode,
    DeltaPending,
    LinkedDelta,
    parentMenuNodeContext,
    queryClient,
  ]);

  return value;
};

export const MenuNodeLeafFeature = ({
  MenuNodeID: providedMenuNodeID,
  menuNodeId = providedMenuNodeID,
  ...props
}) => {
  const value = useDescendantMenuContext(menuNodeId);

  const { XeAppMenuNode } = value;
  const LazyLoadedReactIsMoreTroubleThanItsWorth =
    useLazyLoadedFeature(XeAppMenuNode);

  if (XeAppMenuNode === EMPTY_OBJECT) {
    return null;
  }

  const { MenuNodeID } = XeAppMenuNode;
  const element = createElement(LazyLoadedReactIsMoreTroubleThanItsWorth, {
    MenuNodeID,
    ...props,
  });

  return createElement(
    ReactXeMenuNodeContext.Provider,
    {
      value: value,
    },
    element
  );
};

export const MenuNodeBranchFeature = ({ menuNodeId, ...props }) => {
  const value = useDescendantMenuContext(menuNodeId);

  const { XeAppMenuNode } = value;

  const LazyLoadedReactIsMoreTroubleThanItsWorth =
    useLazyLoadedFeature(XeAppMenuNode);

  useRemountWatcher(
    `MenuNodeBranchFeature : ${XeAppMenuNode.MenuNodeID ?? 'Unknown'}`
  );

  const routes = useFactory(() => {
    if (XeAppMenuNode === EMPTY_OBJECT) {
      return EMPTY_ARRAY;
    }

    const { MenuNodeID, XeAppMenus: AllChildXeAppMenus = EMPTY_ARRAY } =
      XeAppMenuNode;

    const XeAppMenus = AllChildXeAppMenus.filter(isRoutableNode);

    const children = XeAppMenus.reduce(
      createChildRoutes(
        toIsUniqueAmongSiblings(XeAppMenus),
        props?.stickyRoutes
      ),
      []
    );

    return [
      {
        path: ``,
        element: createElement(LazyLoadedReactIsMoreTroubleThanItsWorth, {
          MenuNodeID,
          ...props,
        }),
        children,
      },
    ];
  }, [XeAppMenuNode, props, LazyLoadedReactIsMoreTroubleThanItsWorth]);

  const Route = useRoutes(routes);
  const details = useRouteLocationDetails();

  const stickyUpdates = [...details]
    .reduce((acc, { MenuNodeID }) => {
      const [head = []] = acc;

      if (head.length === 0) {
        return [[MenuNodeID], ...acc];
      }

      return [[MenuNodeID], [...head, MenuNodeID], ...acc];
    }, [])
    .filter((tuple) => tuple.length === 2);

  const { stickyRoutes } = props;

  stickyUpdates.forEach(([parentId, childId]) => {
    stickyRoutes.current[parentId] = childId;
  });

  return createElement(
    ReactXeMenuNodeContext.Provider,
    {
      value: value,
    },
    Route
  );
};

export const RootRouteFeature = () => {
  const { userData = EMPTY_OBJECT } = useEnterprise();
  const { XeAppMenuNode = EMPTY_OBJECT } = useMenuNode();

  useRemountWatcher('RootRouteFeature');

  const stickyRoutes = useRef({});

  const { MenuNodeID, HtmlComponentName: RootHtmlComponentName } =
    XeAppMenuNode;

  //Routing hooks next
  const routes = useFactory(() => {
    if (MenuNodeID === undefined || userData === undefined) {
      return EMPTY_ARRAY;
    }

    const {
      DefaultFeature: DefaultFeatureMenuNodeID,
      DefaultSearchFeature: DefaultSearchFeatureMenuNodeID,
      EnterpriseID = '',
      XeAppMenus = EMPTY_ARRAY,
    } = userData;

    const preliminaryDefaultFeaturePath =
      toPathFromMenuNodes(
        XeAppMenus,
        withMenuNodeID(DefaultFeatureMenuNodeID)
      ) ?? '';
    const preliminaryDefaultSearchFeature =
      toPathFromMenuNodes(
        XeAppMenus,
        withMenuNodeID(DefaultSearchFeatureMenuNodeID)
      ) ?? '';

    //Since we like to ignore the XeApplication portion of our path, remove it please
    //Actually, we need to get the preferred name... HtmlComponentName could be wrong
    const finalDefaultFeaturePath = preliminaryDefaultFeaturePath.replace(
      `/${RootHtmlComponentName}/`,
      ''
    );
    const finalDefaultSearchFeature = preliminaryDefaultSearchFeature.replace(
      `/${RootHtmlComponentName}/`,
      ''
    );

    return [
      finalDefaultFeaturePath && {
        path: ``,
        element: (
          <Navigate
            to={`/${EnterpriseID}/${finalDefaultFeaturePath}/`}
            replace={true}
          />
        ),
      },
      // This is elegant.... but is painfully slow as it forces a full re-render of the whole damn app
      // So this is still here in case something goes to the DefaultSearchFeature on startup, or if someone
      // type it in manually but for now we do some trickery in the usePathNavigate() to also shortcut
      // this in the average case... else the 'navigation' component destroys all of the existing features...
      // even if the place it is navigating to is adjacent
      finalDefaultSearchFeature && {
        path: `DefaultSearchFeature/:ipid`,
        element: (
          <DefaultSearchFeatureNavigate
            to={`/${EnterpriseID}/${finalDefaultSearchFeature}/`}
            replace={false}
          />
        ),
      },
      {
        path: `*`,
        element: (
          <MenuNodeBranchFeature
            key={MenuNodeID}
            menuNodeId={MenuNodeID}
            stickyRoutes={stickyRoutes}
          />
        ),
      },
    ].filter((x) => x);
  }, [userData, MenuNodeID, RootHtmlComponentName]);

  const Route = useRoutes(routes);

  if (MenuNodeID === undefined) {
    return null;
  }

  return Route;
};
