import { useEffect } from 'react';
import {
  CONFIG_PROP_OLD_STYLE,
  useXeAppProperties,
} from '../../XeAppPropertyContext';

const OLD_STYLE_SITES = [];
export const useXnetNextStyleSideEffect = () => {
  //We may need to move this earlier... but it feels ucky being in the enterprise context or sooner as it doesn't have anything to do with those concerns
  const properties = useXeAppProperties();
  const uniqueIdServerPropValue = properties[CONFIG_PROP_OLD_STYLE];

  useEffect(() => {
    if (uniqueIdServerPropValue) {
      /**
       * @type {HTMLElement}
       */
      const bodyEle = window.document.querySelector('body');
      // Just in case, get any existing theme classes and remove them so that
      // we don't ever somehow end up with multiple themes
      const existingThemeClassNames = Array.from(bodyEle.classList).filter(
        (s) => s.match(/xnet-theme.*/)
      );
      bodyEle.classList.remove(...existingThemeClassNames);

      const nextThemeClassName = OLD_STYLE_SITES.includes(
        uniqueIdServerPropValue
      )
        ? ''
        : 'xnet-theme--next';

      const themeClassNames = ['xnet-theme', nextThemeClassName].filter(
        (cn) => cn
      );
      bodyEle.classList.add(...themeClassNames);
    }
  }, [uniqueIdServerPropValue]);
};

export default useXnetNextStyleSideEffect;
