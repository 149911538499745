import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { toLegacyBaseRequestFn } from '../../hooks/scopedReducer';

export const usePatientMenuNodeContextValue = (
  parentMenuNode,
  patientRequestConfigFn,
  requestInvoker,
  ipid
) => {
  const queryClient = useQueryClient();

  const menuNode = useMemo(() => {
    const { UniqueNodeName: parentUniqueNodeName = '' } = parentMenuNode;

    return {
      ...parentMenuNode,
      UniqueNodeName: 'XePatientContext',
      componentPath: `${parentUniqueNodeName}/XePatientContext/${ipid ?? ''}`,
      requestConfigFn: patientRequestConfigFn,
      requestInvoker,
      requestFn: toLegacyBaseRequestFn(
        queryClient,
        patientRequestConfigFn,
        requestInvoker
      ),
    };
  }, [
    parentMenuNode,
    patientRequestConfigFn,
    requestInvoker,
    ipid,
    queryClient,
  ]);

  return menuNode;
};
