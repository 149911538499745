import { useEnterprise } from './XeEnterpriseContext/';
import { useXePatient } from './XePatientContext/';
import { useXeLabelsContext$ } from './XeLabelContext/';
import { useMenuNode } from './XeMenuNodeContext';

export const defaultFeatureContextSet = {
  menuNode: useMenuNode,
  enterprise: useEnterprise,
  patient: useXePatient,
};

export const featureContextSetWithLabels = {
  menuNode: useMenuNode,
  enterprise: useEnterprise,
  patient: useXePatient,
  labels: useXeLabelsContext$,
};
