import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants';
import { toLegacyBaseRequestFn } from '../../../hooks/scopedReducer';

export const useEnterpriseMenuNodeContextValue = (
  userData,
  enterpriseRequestConfigFn,
  requestInvoker
) => {
  const queryClient = useQueryClient();

  const menuNode = useMemo(() => {
    //Need to deal with UniqueNodeName and requestReducers
    const {
      EnterpriseID,
      XeAppMenus: [XeAppMenuNode = EMPTY_OBJECT] = EMPTY_ARRAY,
    } = userData || {};

    return {
      XeAppMenuNode,
      UniqueNodeName: EnterpriseID,
      componentPath: EnterpriseID,
      requestConfigFn: enterpriseRequestConfigFn,
      requestInvoker,
      requestFn: toLegacyBaseRequestFn(
        queryClient,
        enterpriseRequestConfigFn,
        requestInvoker
      ),
    };
  }, [userData, enterpriseRequestConfigFn, requestInvoker, queryClient]);

  return menuNode;
};

export default useEnterpriseMenuNodeContextValue;
