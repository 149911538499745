import { interval, from } from 'rxjs';
import {
  switchMap,
  filter,
  mergeMap,
  distinct,
  tap,
  bufferWhen,
  ignoreElements,
  pluck,
  distinctUntilChanged,
  groupBy,
} from 'rxjs/operators';

import { queryConvert } from 'services/reference-datas/xe-reference-datas-svc';
import { MERGE_PATCH } from '../../schema/JSONSchemaReducer';
import { combineWithLatestFrom } from '../../frp/operators/combineWithLatestFrom';
import { ofChangedPropWhenExists } from '../../frp/operators/ofChangedPropWhenExists';

import { backgroundReducer } from '../../service/toRequestFns';
import { toGetValue } from '../utils/toRequestProxy';
import { toRequestPartitioner } from '../utils/toRequestPartitioner';
import { identity } from 'lodash';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';

const toRelevantRefDataInfo = (refData = {}) => {
  const { AssetName, AssetDisplayColumn, AssetIDColumn } = refData;

  const _d = (refData[AssetName] || []).map((item = {}) => {
    return {
      item,
      text: item[AssetDisplayColumn],
      id: item[AssetIDColumn],
      Active: item.Active,
    };
  });

  return _d;
};

const toServerRefData$ = (action$, state$, { menuNode$, window$ }) => {
  return state$.pipe(
    ofChangedPropWhenExists('requestSubject$'),
    combineWithLatestFrom(window$.pipe(pluck('location'))),
    switchMap(([requestSubject$, location]) => {
      const requestPartitioner = toRequestPartitioner(
        location,
        queryConvert,
        '&referenceDataId='
      );

      return requestSubject$.pipe(
        groupBy((request) => request?.hashKey),
        mergeMap((group$) => {
          return group$.pipe(
            distinct((request) => request?.prop),
            bufferWhen(() => interval(50)),
            filter((ar) => ar.length > 0),
            mergeMap((requests) => {
              const [{ hashKey, requestConfigFn }] = requests;

              const partitionedKeys = requestPartitioner(
                requests.map((request) => request?.prop)
              );
              return from(
                partitionedKeys.map((requestKeys) => {
                  return {
                    hashKey,
                    requestConfigFn,
                    requestKeys,
                  };
                })
              );
            }),
            combineWithLatestFrom(menuNode$.pipe(pluck('requestInvoker'))),
            mergeMap(
              ([{ hashKey, requestConfigFn, requestKeys }, requestInvoker]) => {
                const finalConfig = requestConfigFn(
                  backgroundReducer
                )(/*No more reducers*/)(
                  queryConvert({ referenceDataId: requestKeys }, identity)
                );

                return requestInvoker(/*No additional args*/)(finalConfig).then(
                  ({ results: values = EMPTY_ARRAY } = EMPTY_OBJECT) => {
                    const value = requestKeys.reduce((acc, id) => {
                      const refDatem =
                        values.find(
                          ({ ReferenceDataID }) => ReferenceDataID === id
                        ) || {};

                      acc[id] = toRelevantRefDataInfo(refDatem);
                      return acc;
                    }, {});

                    return {
                      type: MERGE_PATCH,
                      path: ['map', hashKey],
                      value,
                    };
                  }
                );
              }
            )
          );
        })
      );
    })
  );
};

const toRefDataSubjectSideEffect$ = (action$, state$) => {
  return state$.pipe(
    ofChangedPropWhenExists('map'),
    combineWithLatestFrom(
      state$.pipe(ofChangedPropWhenExists('requestSubject$')),
      state$.pipe(ofChangedPropWhenExists('subject$'))
    ),
    distinctUntilChanged(),
    tap(([map, requestSubject$, subject$]) => {
      const nextObservation = {
        map,
        getValue: toGetValue(map, requestSubject$, []),
      };
      subject$.next(nextObservation);
    }),
    ignoreElements()
  );
};

export default [toServerRefData$, toRefDataSubjectSideEffect$];
