import {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useCallback,
  createContext,
  createElement,
} from 'react';

import { useReducer$ } from '../../hooks/useReducer$';
import { useMenuNode } from '../XeMenuNodeContext';
import { combineReducers } from '../../fp/fp';

import { combineEpics } from 'redux-observable';
import { BehaviorSubject, noop, of } from 'rxjs';

import epics from './epics';
import reducers from './reducers';

import { toProxyTrap, updatesOurData } from '../utils/toProxyTrap';
import { EMPTY_OBJECT } from '../../constants';
import { useSystem } from '../XeSystemContext';
import { useXeAuthentication } from '../XeAuthenticationContext';

export const CONFIG_PROP_AG_GRID_LICENSE_KEY = 'ag-grid-license';
export const CONFIG_PROP_OLD_STYLE = 'xnet.site.uniqueid';

export const XeAppPropertyInitialContextValue = Object.freeze({
  map: EMPTY_OBJECT,
  getValue: noop,
});

export const XeAppPropertyInitialContext$ = new BehaviorSubject(
  XeAppPropertyInitialContextValue
);

export const ReactXeAppPropertyContext = createContext(
  XeAppPropertyInitialContext$
);
ReactXeAppPropertyContext.displayName = 'XeAppPropertyContext';

export const useXeAppProperties = (status) => {
  const { requestConfigFn } = useXeAuthentication();

  const hashKey = 'global';
  const subject$ = useContext(ReactXeAppPropertyContext);
  const proxyTrap = useRef(
    toProxyTrap(subject$.value, hashKey, requestConfigFn)
  );
  const [, forceRender] = useReducer((s) => s + 1, 0);

  useEffect(() => {
    const subscription = subject$.subscribe(({ map, getValue }) => {
      const { relevant } = proxyTrap.current;

      const ourMap = map?.[hashKey] ?? EMPTY_OBJECT;
      if (updatesOurData(relevant, ourMap)) {
        const updated = Object.keys(relevant).reduce((acc, k) => {
          acc[k] = ourMap[k];
          return acc;
        }, {});

        proxyTrap.current = toProxyTrap(
          { map, getValue },
          hashKey,
          requestConfigFn,
          updated
        );
        forceRender({});
      }

      //this line may be optional in the new config if we get this all right
      proxyTrap.current = toProxyTrap(
        { map, getValue },
        hashKey,
        requestConfigFn,
        relevant
      );
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [subject$, hashKey, requestConfigFn]);

  return proxyTrap.current.proxy;
};

const reducer = combineReducers(...reducers);
const epic = combineEpics(...epics);

export const XeAppPropertyContext = (props) => {
  const { environment: { toWindow } = EMPTY_OBJECT } = useSystem();

  const menuNode = useMenuNode();
  const epicWithDeps = useCallback(
    (action$, state$) => {
      return epic(action$, state$, {
        menuNode$: of(menuNode),
        window$: of(toWindow()),
      });
    },
    [menuNode, toWindow]
  );
  const [{ subject$ } = {}] = useReducer$(reducer, epicWithDeps);

  const { children } = props;
  //console.log('Render RefData');
  return subject$
    ? createElement(
        ReactXeAppPropertyContext.Provider,
        {
          value: subject$,
          key: 'ReactXeAppPropertyContext.Provider',
        },
        children
      )
    : null;
};
