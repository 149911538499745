import { EMPTY_OBJECT } from '../../constants';

export const toGetValue =
  (map = EMPTY_OBJECT, requestSubject$, defaultValue = '') =>
  (prop, hashKey = '', requestConfigFn = undefined) => {
    // NOTE: There are places in the application that dynamically get labels (eg. <Grid />).
    // In an attempt to fix this at the proxy level instead of checking for an `undefined` key
    // before we get here, we are explicitly checking for a string key called "undefined" (JDM)
    if (typeof prop !== 'string' || prop === 'undefined') {
      return undefined;
    }

    const { [hashKey]: target = EMPTY_OBJECT } = map;

    if (prop in target) {
      return target[prop];
    }

    if (requestSubject$) {
      requestSubject$.next({
        hashKey,
        requestConfigFn,
        prop,
      });
    }

    return defaultValue;
  };
