import has from 'lodash/has';
import { HEADER_XE_ENTERPRISE_SCOPE } from '../../service/constants';
import { toHeaderReducer } from '../../service/toRequestFns';

export const toEnterpriseScopeHeaderReducerFromPatient = (patient) => {
  // If there's no patient, or the supplied object has no scope, just make a normal request.
  if (!patient || !has(patient, 'EnterpriseScope')) {
    return (config) => config;
  }

  // We have a patient, so be sure to reduce to it's scope for descendant calls.
  const { EnterpriseScope } = patient;

  return (config) => {
    return toHeaderReducer(HEADER_XE_ENTERPRISE_SCOPE)(EnterpriseScope)(config);
  };
  //return toHeaderReducer(HEADER_XE_ENTERPRISE_SCOPE)(EnterpriseScope);
};
