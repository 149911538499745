import { useContext, useMemo, createContext } from 'react';

import { ReactXeMenuNodeContext, useMenuNode } from '../XeMenuNodeContext';

import { loginEnterprise } from 'services/user-datas/xe-user-datas-svc';

import { useXeQuery } from '../../data/useXeQuery';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { pluck } from '../../fp/object';

import { toHeaderReducer } from '../../service/data';
import { EMPTY_OBJECT } from '../../constants';
import { useRemountWatcher } from '../XeMenuNodeContext/components';
import { useUserDataValidationSideEffect } from '../utils/userData/validator';
import useKeepAliveSideEffect$ from './hooks/useKeepAliveSideEffect$';
import useInactivityTimeout$ from './hooks/useInactivityTimeout$';
import useEnterpriseMenuNodeContextValue from './hooks/useEnterpriseMenuNodeContextValue';
import useMenuNodeCache from './hooks/useMenuNodeCache';
import useAGGridRegistrationSideEffect from './hooks/useAGGridRegistrationSideEffect';
import useXnetNextStyleSideEffect from './hooks/useXnetNextStyleSideEffect';
import { HEADER_XE_ENTERPRISE_ID } from '../../service/constants';

export const XeEnterpriseInitialContext = Object.freeze({
  userData: EMPTY_OBJECT,
  requestConfigFn: () => console.error('Calling undefined request fn'),
  requestInvoker: () => console.error('Calling undefined request fn'),
});

export const ReactXeEnterpriseContext = createContext(
  XeEnterpriseInitialContext
);
ReactXeEnterpriseContext.displayName = 'XeEnterpriseContext';

export const useEnterprise = () => {
  return useContext(ReactXeEnterpriseContext);
};

const toEnterpriseReducer = (enterpriseId) => {
  return toHeaderReducer(HEADER_XE_ENTERPRISE_ID)(enterpriseId);
};

const toEnterpriseRequestConfigFn = (enterpriseId, requestConfigFn) => {
  if (enterpriseId === undefined) {
    return requestConfigFn;
  }
  return requestConfigFn(toEnterpriseReducer(enterpriseId));
};

//This is just a bit of an optimization, instead of re-rendering the entire enterprise context when the side effect hooks fire,
//we will just re-render this container
const EnterpriseSideEffectContainer = ({ userData }) => {
  useRemountWatcher('EnterpriseSideEffectContainer');

  useKeepAliveSideEffect$(userData);
  useInactivityTimeout$(userData);
  useAGGridRegistrationSideEffect();
  useXnetNextStyleSideEffect();

  return null;
};

const pluckEnterprise = pluck('EnterpriseID');
const pluckRootMenuNodeId = pluck('XeAppMenus', 0, 'MenuNodeID');
export const XeEnterpriseContext = (props) => {
  const [searchParams] = useSearchParams();
  const returnRoute = searchParams.get('route') ?? '';

  useRemountWatcher('XeEnterpriseContext');

  const { enterpriseId } = useParams();

  const { requestInvoker, requestConfigFn } = useMenuNode();

  const enterpriseRequestConfigFn = useMemo(
    () => toEnterpriseRequestConfigFn(enterpriseId, requestConfigFn),
    [enterpriseId, requestConfigFn]
  );

  const cacheLoginEnterpriseAndMenuNodes = useMenuNodeCache(
    enterpriseRequestConfigFn
  );

  const { data: userData, isError: isAuthorizationError } = useXeQuery(
    loginEnterprise({}, (x) => x),
    {
      staleTime: Infinity,
      onSuccess: cacheLoginEnterpriseAndMenuNodes,
      requestConfigFn: enterpriseRequestConfigFn,
    }
    //{ enabled : enterpriseId !== undefined }
  );

  //Check the user data to make sure it is valid per our rules
  useUserDataValidationSideEffect(userData);

  const menuNodeId = pluckRootMenuNodeId(userData);
  const responseEnterpriseId = pluckEnterprise(userData);

  const navigate = useNavigate();
  useEffect(() => {
    //If we experience a login error to this enterprise, we need to assume this is a bad link
    //or bad intention.... in either case, it's time to bail out
    if (isAuthorizationError) {
      navigate(`/denied`, { replace: true });
    }
  }, [isAuthorizationError, navigate]);

  useEffect(() => {
    const destinationEnterprise = responseEnterpriseId ?? enterpriseId;
    if (destinationEnterprise && destinationEnterprise !== enterpriseId) {
      navigate(`/${responseEnterpriseId ?? enterpriseId}${returnRoute}`);
    }
  }, [responseEnterpriseId, enterpriseId, navigate, returnRoute]);

  const enterpriseContextValue = useMemo(() => {
    return {
      userData,
      requestConfigFn: enterpriseRequestConfigFn,
      requestInvoker,
    };
  }, [userData, enterpriseRequestConfigFn, requestInvoker]);

  const menuNodeContextValue = useEnterpriseMenuNodeContextValue(
    userData,
    enterpriseRequestConfigFn,
    requestInvoker
  );

  const readyToRender = !(
    enterpriseId === undefined || menuNodeId === undefined
  );
  if (!readyToRender) {
    return null;
  }

  return (
    <ReactXeEnterpriseContext.Provider
      key={`XeEnterpriseContext.${enterpriseId}.Provider`}
      value={enterpriseContextValue}
    >
      <ReactXeMenuNodeContext.Provider
        key={'XeEnterpriseContext.Scope'}
        value={menuNodeContextValue}
      >
        <EnterpriseSideEffectContainer userData={userData} />
        {props?.children}
      </ReactXeMenuNodeContext.Provider>
    </ReactXeEnterpriseContext.Provider>
  );
};
