import { useEffect$ } from '../../../hooks/useEffect$';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filter, NEVER, tap } from 'rxjs';
import { EMPTY_OBJECT } from '../../../constants';

export const AUTHENTICATED = 'authenticated';
export const NOT_ATTEMPTED = 'not attempted';
export const PENDING = 'pending';
export const FAILED = 'failed';

export default (universe, authClient) => {
  const {
    environment: { toWindow } = EMPTY_OBJECT,
    streams: { toServiceErrors$ } = EMPTY_OBJECT,
  } = universe;
  const navigate = useNavigate();
  const [authenticationStatus, setAuthStatus] = useState(NOT_ATTEMPTED);

  useEffect(() => {
    if (!authClient || authenticationStatus === FAILED) {
      const { location: { hash = '' } = EMPTY_OBJECT } = toWindow();

      if (hash.length > 1) {
        const cleanedHash = hash.slice(1); //get rid of actual hash mark
        navigate(`/authenticate?route=${encodeURIComponent(cleanedHash)}`, {
          replace: true,
        });
        return null;
      }

      navigate('/authenticate', { replace: true });
      return null;
    }

    if (authClient && authenticationStatus === NOT_ATTEMPTED) {
      setAuthStatus(PENDING);
      authClient.isAuthenticated().then((authenticated) => {
        if (authenticated) {
          setAuthStatus(AUTHENTICATED);
        } else {
          setAuthStatus(FAILED);
        }
      });
    }
  }, [authClient, authenticationStatus, navigate, toWindow]);

  useEffect$(() => {
    if (authenticationStatus !== AUTHENTICATED) return NEVER;

    return toServiceErrors$().pipe(
      filter(({ status } = {}) => status === 403 || status === 401),
      tap(() => {
        navigate('/denied', { replace: true });
      })
    );
  }, [authenticationStatus, navigate, toWindow, toServiceErrors$]);

  return authenticationStatus;
};
