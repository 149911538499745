import { pluck } from '../../fp/object';

export const nestDelta = (Delta) => {
  if (Delta && Delta.services) {
    console.error(
      `Delta configuration error, found key services at the top level`
    );
    return {
      Menu: {
        services: Delta.services,
      },
    };
  }

  return Delta;
};

const mostSpecificServiceDeltaFirst = (
  { service: serviceA, action: actionA } = {},
  { service: serviceB, action: actionB } = {}
) => {
  const serviceSort = serviceB.localeCompare(serviceA);
  return serviceSort || actionB.localeCompare(actionA);
};

const toDeltaServiceList = (o = {}) => {
  return pluck(0, 'services', 'service')(Object.values(o));
};

export const toServiceDelta = (Delta) => {
  const serviceList = [...(toDeltaServiceList(Delta) || [])].sort(
    mostSpecificServiceDeltaFirst
  );

  return (config) => {
    if (!config || !serviceList.length) return undefined;
    /*
      The action is the same as the method name of the java rest service class.
      And the java rest service class gets it's name from the handlers action name.

      There is one exception. If the action is 'delete' we rename it to 'doDelete' because Javascript will complain.
      jcl.

      Per SYNUI-3959
      Now supports fallback with '*'
      Always tries to find the most action and servicefirst falling back to * in the event a specific one does not exist
    */
    const candidateServices = serviceList.filter(
      (item) => item.service === config.service || item.service === '*'
    );
    return (
      candidateServices.find((item) => item.action === config.action) ||
      candidateServices.find((item) => item.action === '*')
    );
  };
};

export const hasDeltas = (Delta = {}) => Object.keys(Delta).length > 0;

export const toDeltaReducer = (Delta = {}) => {
  if (!hasDeltas(Delta)) return (config) => config;
  const toApplicableDelta = toServiceDelta(Delta);

  return (config = {}) => {
    const applicableDelta = toApplicableDelta(config);
    if (!applicableDelta) return config;

    const { headers } = config;
    const { headers: deltaHeaders } = applicableDelta;

    return {
      ...config,
      headers: { ...headers, ...deltaHeaders },
    };
  };
};
