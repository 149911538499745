import { pipe } from 'lodash/fp';
import { createContext, Fragment, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPatient } from 'services/patients/xe-patients-svc';
import { EMPTY_OBJECT, NOOP_FUNCTION } from '../../constants';
import { useXeQuery } from '../../data/useXeQuery';
import { pluck } from '../../fp/object';
import { XePatientSearchWidget } from '../../widgets/XePatientSearchWidget';
import { useXeLabels } from '../XeLabelContext';
import { ReactXeMenuNodeContext, useMenuNode } from '../XeMenuNodeContext';
import { useRemountWatcher } from '../XeMenuNodeContext/components';
import { usePatientMenuNodeContextValue } from './hooks';
import { toEnterpriseScopeHeaderReducerFromPatient } from './requestFns';
import { toServiceDelta } from '../XeMenuNodeContext/deltas';
import { HEADER_XE_ENTERPRISE_SCOPE } from '../../service/constants';

export const XePatientInitialContext = Object.freeze({
  ipid: undefined,
  requestConfigFn: () => console.error('Calling undefined request fn'),
});

export const ReactXePatientContext = createContext(XePatientInitialContext);
ReactXePatientContext.displayName = 'XePatientContext';

export const useXePatient = () => {
  return useContext(ReactXePatientContext);
};

const toIPID = pluck('IPID');
const DefaultPatientSelectionWidget = (props) => {
  const { onSelection = NOOP_FUNCTION } = props;

  const { ipid } = useXePatient();
  const labels = useXeLabels();

  return (
    <XePatientSearchWidget
      descriptor={labels.Patient}
      valueFn={toIPID}
      onChange={(x) => {
        onSelection(x);
      }}
      value={ipid}
    />
  );
};

//Component which exposes SelectionWidet from Hook
export const PatientSelectionWidget = () => {
  const { SelectionWidget = Fragment } = useContext(ReactXePatientContext);
  return SelectionWidget;
};

export const withImpliedPatientContextDeltas = (responseSet) => {
  const delta = [
    {
      services: {
        service: [
          {
            service: 'xe-patients-svc',
            action: 'getPatient',
            headers: {
              [HEADER_XE_ENTERPRISE_SCOPE]: undefined,
            },
            params: {
              responseSet,
            },
          },
        ],
      },
    },
  ];

  const toApplicableDelta = toServiceDelta(delta);

  return (config = {}) => {
    const applicableDelta = toApplicableDelta(config);
    if (!applicableDelta) return config;

    const { headers, params } = config;
    const { headers: deltaHeaders, params: deltaParams } = applicableDelta;

    return {
      ...config,
      headers: { ...headers, ...deltaHeaders },
      params: { ...params, ...deltaParams },
    };
  };
};

export const XePatientContext = (props) => {
  useRemountWatcher('XePatientContext');
  const { requestInvoker, requestConfigFn } = useMenuNode();
  const {
    XeAppMenuNode: { HtmlComponentName: ChildFeatureName } = EMPTY_OBJECT,
  } = props;

  //TODO : Discuss with Mark on a better way to handle this
  const responseSet =
    ChildFeatureName === 'XePatientSide'
      ? 'CONTEXT_PATIENT_BAR_SIDE'
      : undefined;

  const { ipid: IPIDString } = useParams();
  const ipid = parseInt(IPIDString);

  const { data: patientData } = useXeQuery(
    getPatient({ ipid, responseSet }, (x) => x),
    {
      staleTime: 90000,
      enabled: !isNaN(ipid),
      select: ([patient] = []) => patient,
    }
  );

  const EnterpriseScope = patientData?.EnterpriseScope;

  const patientRequestConfigFn = useMemo(() => {
    return EnterpriseScope
      ? requestConfigFn(
          pipe(
            toEnterpriseScopeHeaderReducerFromPatient({ EnterpriseScope }),
            withImpliedPatientContextDeltas(responseSet)
          )
        )
      : requestConfigFn(withImpliedPatientContextDeltas(responseSet));
  }, [EnterpriseScope, requestConfigFn, responseSet]);

  const menuNode = useMenuNode();
  const patientContextValue = useMemo(() => {
    return {
      ipid,
      requestConfigFn: patientRequestConfigFn,
    };
  }, [ipid, patientRequestConfigFn]);

  const menuNodeContextValue = usePatientMenuNodeContextValue(
    menuNode,
    patientRequestConfigFn,
    requestInvoker,
    ipid
  );

  const readyToRender = !(patientData === undefined);

  if (!readyToRender) {
    return null;
  }

  return (
    <ReactXePatientContext.Provider
      key={`ReactXePatientContext.${ipid}.Provider`}
      value={patientContextValue}
    >
      <ReactXeMenuNodeContext.Provider
        key={'ReactXePatientContext.Scope'}
        value={menuNodeContextValue}
      >
        {props?.children}
      </ReactXeMenuNodeContext.Provider>
    </ReactXePatientContext.Provider>
  );
};

export const XeSearchablePatientContext = (props) => {
  const { requestInvoker, requestConfigFn } = useMenuNode();
  const [ipid, setIpid] = useState(undefined);

  const numericIpid = parseInt(ipid);
  const { data: patientData } = useXeQuery(
    getPatient({ ipid }, (x) => x),
    {
      staleTime: 90000,
      enabled: !isNaN(numericIpid),
      select: ([patient] = []) => patient,
    }
  );

  const EnterpriseScope = patientData?.EnterpriseScope;

  const patientRequestConfigFn = useMemo(() => {
    return EnterpriseScope
      ? requestConfigFn(
          toEnterpriseScopeHeaderReducerFromPatient({ EnterpriseScope })
        )
      : requestConfigFn;
  }, [EnterpriseScope, requestConfigFn]);

  const menuNode = useMenuNode();
  const patientContextValue = useMemo(() => {
    return {
      ipid,
      requestConfigFn: patientRequestConfigFn,
      SelectionWidget: <DefaultPatientSelectionWidget onSelection={setIpid} />,
    };
  }, [ipid, patientRequestConfigFn]);

  const menuNodeContextValue = usePatientMenuNodeContextValue(
    menuNode,
    patientRequestConfigFn,
    requestInvoker
  );

  return (
    <ReactXePatientContext.Provider
      key={`ReactXePatientContextLocal.Provider`}
      value={patientContextValue}
    >
      <ReactXeMenuNodeContext.Provider
        key={'ReactXePatientContext.Scope'}
        value={menuNodeContextValue}
      >
        {props?.children}
      </ReactXeMenuNodeContext.Provider>
    </ReactXePatientContext.Provider>
  );
};

export default XePatientContext;
