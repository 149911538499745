import { toVerbActionReducer } from '../../schema/JSONSchemaReducer';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { toGetValue } from '../utils/toRequestProxy';
import { EMPTY_OBJECT } from '../../constants';

const toDefaultState = (state) => {
  if (state) return state;

  const map = EMPTY_OBJECT;
  const requestSubject$ = new ReplaySubject(100);
  const subject$ = new BehaviorSubject({
    map,
    getValue: toGetValue(map, requestSubject$, []),
  });

  return {
    map,
    subject$,
    requestSubject$,
  };
};

export default [toDefaultState, toVerbActionReducer()];
