import { useEffect$ } from '../../../hooks/useEffect$';
import {
  debounceTime,
  filter,
  ignoreElements,
  merge,
  NEVER,
  startWith,
  tap,
} from 'rxjs';

import { usePathNavigate } from '../../../hooks/usePathNavigate';
import { useSystem } from '../../XeSystemContext';
import { EMPTY_OBJECT } from '../../../constants';

const minutesToMilliseconds = (time) => time * 60 * 1000;
export const useInactivityTimeout$ = ({ InactivityTimeout } = EMPTY_OBJECT) => {
  const navigate = usePathNavigate();
  const universe = useSystem();

  useEffect$(() => {
    if (InactivityTimeout === undefined) {
      return NEVER;
    }

    const {
      environmentEpics: {
        appInteractive$: toAppInteractive$,
        userActivity$: toUserActivity$,
      } = EMPTY_OBJECT,
    } = universe;

    //These are epics today, so this looks a little strange but it will be an easy refactor in the future
    const appInteractive$ = toAppInteractive$(undefined, undefined, universe);
    const userActivity$ = toUserActivity$(undefined, undefined, universe);

    return merge(
      appInteractive$.pipe(
        filter(({ value: interactive }) => interactive),
        startWith({ value: true })
      ), //Only let us know if it changed to focused from unfocused
      userActivity$.pipe(startWith({ value: new Date() })) //Let's us know if the user did anything in the last 5 seconds
    ).pipe(
      debounceTime(minutesToMilliseconds(InactivityTimeout)),
      tap(() => {
        //We have neither observed a focus in nor any interaction for the duration of the InactivityTimeout...
        //Time to logout
        navigate('/logout');
      }),
      ignoreElements()
    );
  }, [universe, navigate, InactivityTimeout]);
};

export default useInactivityTimeout$;
