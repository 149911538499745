import {
  CONFIG_PROP_AG_GRID_LICENSE_KEY,
  useXeAppProperties,
} from '../../XeAppPropertyContext';
import { LicenseManager } from 'ag-grid-enterprise';
import { useEffect } from 'react';

export const useAGGridRegistrationSideEffect = () => {
  const properties = useXeAppProperties();
  const license = properties[CONFIG_PROP_AG_GRID_LICENSE_KEY];

  useEffect(() => {
    if (license) {
      LicenseManager.setLicenseKey(license);
    }
  }, [license]);
};

export default useAGGridRegistrationSideEffect;
