import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { cacheMenuTree } from '../../utils/userData/caching.js';

export const useMenuNodeCache = (enterpriseRequestConfigFn) => {
  const queryClient = useQueryClient();

  return useCallback(
    (data) => {
      cacheMenuTree(queryClient, enterpriseRequestConfigFn, data);
    },
    [queryClient, enterpriseRequestConfigFn]
  );
};

export default useMenuNodeCache;
