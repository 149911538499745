import {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useCallback,
  createContext,
  createElement,
  useMemo,
} from 'react';

import { useReducer$ } from '../../hooks/useReducer$';
import { useMenuNode } from '../XeMenuNodeContext';
import { combineReducers } from '../../fp/fp';

import { combineEpics } from 'redux-observable';
import { BehaviorSubject, of } from 'rxjs';

import epics from './epics';
import reducers from './reducers';

import { toProxyTrap, updatesOurData } from '../utils/toProxyTrap';
import { useSystem } from '../XeSystemContext';
import { EMPTY_OBJECT } from '../../constants';
import { hashQueryKey } from 'react-query';
import { toCacheKeyFromRequest } from '../../service/serviceCache';
import { queryCache } from 'services/labels/xe-labels-svc';

export const XeLabelInitialContextValue = Object.freeze({
  map: EMPTY_OBJECT,
  getValue: (prop /*hashKey = '', requestConfigFn*/) => prop,
});

export const XeLabelInitialContext$ = new BehaviorSubject(
  XeLabelInitialContextValue
);

export const ReactXeLabelContext = createContext(XeLabelInitialContext$);

ReactXeLabelContext.displayName = 'XeLabelContext';

export const useXeLabelsContext$ = () => {
  return useContext(ReactXeLabelContext);
};

export const useXeLabels = () => {
  const { requestConfigFn } = useMenuNode();

  //This is just an optimization to prevent recomputation at each request
  const hashKey = useMemo(() => {
    return hashQueryKey(
      toCacheKeyFromRequest(queryCache({}, requestConfigFn()))
    );
  }, [requestConfigFn]);

  const subject$ = useContext(ReactXeLabelContext);
  const proxyTrap = useRef(
    toProxyTrap(subject$.value, hashKey, requestConfigFn)
  );
  const [, forceRender] = useReducer((s) => s + 1, 0);

  useEffect(() => {
    const subscription = subject$.subscribe(({ map, getValue }) => {
      const { relevant } = proxyTrap.current;

      const ourMap = map?.[hashKey] ?? EMPTY_OBJECT;
      //We only care to update the proxy if the updates coming from the context is relevant to our requested labels
      if (updatesOurData(relevant, ourMap)) {
        const updated = Object.keys(relevant).reduce((acc, k) => {
          acc[k] = ourMap[k];
          return acc;
        }, {});

        proxyTrap.current = toProxyTrap(
          { map, getValue },
          hashKey,
          requestConfigFn,
          updated
        );
        forceRender({});
      } else {
        //this line may be optional in the new config if we get this all right
        proxyTrap.current = toProxyTrap(
          { map, getValue },
          hashKey,
          requestConfigFn,
          relevant
        );
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [subject$, hashKey, requestConfigFn]);

  return proxyTrap.current.proxy;
};

const reducer = combineReducers(...reducers);
const epic = combineEpics(...epics);

export const XeLabelContext = (props) => {
  const { environment: { toWindow } = EMPTY_OBJECT } = useSystem();

  const menuNode = useMenuNode();
  const epicWithDeps = useCallback(
    (action$, state$) => {
      return epic(action$, state$, {
        menuNode$: of(menuNode),
        window$: of(toWindow()),
      });
    },
    [menuNode, toWindow]
  );
  const [{ subject$ } = {}] = useReducer$(reducer, epicWithDeps);

  const { children } = props;
  return subject$
    ? createElement(
        ReactXeLabelContext.Provider,
        {
          value: subject$,
          key: 'ReactXeLabelContext.Provider',
        },
        children
      )
    : null;
};
