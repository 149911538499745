import {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  createElement,
  createContext
} from 'react';
import { useEnterprise } from '../XeEnterpriseContext';
import { BehaviorSubject } from 'rxjs';

const defaultContextValue = new BehaviorSubject(
  new Proxy(
    {},
    {
      get: () => false,
    }
  )
);

const ReactXeUserRightsContext = createContext(defaultContextValue);
ReactXeUserRightsContext.displayName = 'XeUserRightsContext';

export const useXeRights = () => {
  const [, forceRender] = useReducer((s) => s + 1, 0);

  const rights$ = useContext(ReactXeUserRightsContext);
  const { value = {} } = rights$;

  const lastProxy = useRef(value);

  useEffect(() => {
    const subscription = rights$.subscribe((proxy) => {
      //If we are provided a new proxy, go ahead and re-render this view....
      //To be clear this can't happen right now anyway
      if (!lastProxy.current || !lastProxy === proxy) {
        lastProxy.current = proxy;
        forceRender({});
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [rights$]);

  return value;
};

const toRightsProxy = (userData) => {
  const { RightID = '' } = userData;

  const handler = {
    get: function (rightSet, right = '') {
      return rightSet.has(right);
    },
  };

  const set = new Set(RightID.split('|'));
  return new Proxy(set, handler);
};

export const XeUserRightsContext = (props) => {
  const { children } = props;
  const enterprise = useEnterprise();

  const subject$ = useMemo(() => {
    const { userData = {} } = enterprise;

    return new BehaviorSubject(toRightsProxy(userData));
  }, [enterprise]);

  return createElement(
    ReactXeUserRightsContext.Provider,
    {
      value: subject$,
      key: 'XeUserRightsContext.Provider',
    },
    children
  );
};
