import { identity } from '../../fp/fp';

const LONGEST_GET_REQUEST = 1800; //actually 2048 but trying to play it safe;

export const toRequestPartitioner = (location, serviceFn, token = '') => {
  //without a lot more work, best we can do here is take a viable guess and a little hardcoding
  //If the service we call changes, we need to update this code
  //Right now the server is at :
  const { url } = serviceFn({}, identity);
  const { origin } = location;
  const serverURLOverhead = `${String(origin)}${url}`;
  const serverOverhead = serverURLOverhead.length;

  //Each subsequent label adds a toke, e.g. '&labelId=';
  const tokenOverhead = token.length;

  return (values) => {
    const partitioned = values.reduce((acc, value) => {
      const [head = {}, ...rest] = acc;

      const { size = serverOverhead, requesting = [] } = head;

      const newSize = size + value.length + tokenOverhead;
      if (newSize < LONGEST_GET_REQUEST) {
        return [
          {
            size: newSize,
            requesting: [...requesting, value],
          },
          ...rest,
        ];
      } else {
        return [
          {
            size: serverOverhead + value.length + tokenOverhead,
            requesting: [value],
          },
          head,
          ...rest,
        ];
      }
    }, []);

    const partitionedRequests = partitioned.map(({ requesting }) => requesting);

    return partitionedRequests;
  };
};
